.cropper-view-item {
  cursor: pointer;
}

.content-status {
  .badge {
    margin-right: 0.25rem;
  }
  .badge-release {
    background-color: #ac7ee9;
    color: #ffffff;
  }

  .badge-warning {
    color: #ffffff;
  }
}

.user-avatar {
  border-radius: 25px;
  width: 25px;
  height: 25px;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  padding: 10px;
  flex: 1;
}

@media (min-width: 768px) {
  .main {
    padding: 20px;
  }
}

.content-main {
  display: flex;
  flex-direction: row;
}

//
// Aside
//

.aside {
  border-left: 1px solid #dce0e5;

  position: relative;
  background-color: white;
}
.aside-content {
  width: 300px;
  height: 100%;
  padding: 10px;
}

.aside small {
  display: block;
  margin-bottom: 10px;
  margin-top: -5px;
  color: #666;
}

@media (max-width: 767px) {
  .aside {
    display: none;
  }
}

//

.leaflet-container {
  height: 30vh;
  width: 100%;
}

.content-fields {
  padding: 12px 14px;

  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 4px;

  margin-bottom: 30px;
  background: #fff;
}

.content-editor {
  position: relative;
}

//
// Toolbar
//

.content-toolbar {
  height: 50px;

  position: sticky;

  top: 0;
  left: 250px;
  right: 0;

  color: #fff;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.content-toolbar-success {
    background-color: #5cb85c;
  }

  &.content-toolbar-warning {
    background-color: #f0ad4e;
  }

  &.content-toolbar-release {
    background-color: #ac7ee9;
  }
  &.content-toolbar-dark {
    background-color: #636f83;
  }

  .btn-release {
    background: #ac7ee9;
    color: white;
  }

  .btn-toolbar {
    margin-right: 10px;
  }

  .btn-group {
    margin-right: 10px;
  }

  .btn-preview {
    font-size: 20px;
    border: solid 2px rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-preview i {
    margin: 0;
    height: unset;
    width: unset;
  }
}

//
// Rich text
//

.RichEditor-link {
  color: #3b5998 !important;
  text-decoration: underline !important;
}

.RichEditor-btn {
  padding: 5px;
  color: #ffffff;
  background-color: #337ab7;
  border-radius: 5px;
  text-decoration: none;
}

.public-DraftStyleDefault-block {
  /*  */
  margin-bottom: 14px;
}

.RichEditor-blockquote {
  border-left: 5px solid #cccccc;
  padding-left: 10px;
}

.RichEditor-root {
  border: solid 1px #eee;
  border-radius: 5px;
}

.RichEditor-toolbar {
  /* padding: 8px 0px; */

  padding: 3px 0px;
  border-radius: 5px;
  margin-right: auto;
  margin-left: 10px;
}

.RichEditor-toolbar,
.RichEditor-toolbar select {
  /* background: #eee; */
  background: #fff;
}

.RichEditor-controls {
  display: inline-block;
  padding: 0px 10px;

  color: #666;
  border-right: solid 1px #999;
}

.RichEditor-controls:last-child {
  border-right: none;
}

.RichEditor-editor {
  min-height: 3em;
  padding: 10px;
  min-width: 20em;
}

.RichEditor-styleButton {
  border-radius: 5px;
  /* border: solid 1px #ccc; */
  padding: 2px;
  margin: 2px;
  display: inline-block;
}

.RichEditor-activeButton {
  background-color: #ccc;
}

.editor-media {
  max-width: 100%;
}

.editor-embed {
  padding: 10px;
  border: solid 1px #999;
  background: #eee;
}

/*
Fields
*/

.content-field {
  border-left: 3px solid #ddd;
  margin-bottom: 20px;
  padding-left: 5px;
  margin-right: 10px;
}

.content-field-translateable {
  border-left: 3px solid #2196f3;
}

.content-field label {
  color: #999;
  display: block;
  margin-bottom: 0px;
}
.content-field-translateable > div label {
  margin-top: 15px;
}

.content-field-translateable > div:first-child label {
  margin-top: 0px;
}

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker-wrapper {
  margin-left: 10px;
}

.field-icon {
  color: #999;
}

.content-field .form-check-input {
  position: relative;
}

.content-field input.form-control {
  max-width: 400px;
  display: inline-block;
}

/* Modules */

.modules-toolbar {
  text-align: center;
}

.add-module-btn {
  border-radius: 50%;
  background: #222;
  color: #eee;
  width: 30px;
  height: 30px;
  border: none;
}

.modules-library {
  align-items: center;
  justify-content: center;

  display: flex;
  flex-flow: row;
  margin: 20px 0;
  flex-wrap: wrap;
}

.content-module {
  word-break: break-word;
  position: relative;
  padding: 5px;
  margin: 5px;

  border-radius: 5px;
  border: solid 1px #ccc;
  background-color: #fff;
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.content-module .module-info {
  position: absolute;
  top: 0px;
  right: 0px;
}

.repeating-list,
.modules-list {
  display: flex;
  flex-flow: column nowrap;
  padding: 2px;
  position: relative;
}

.modules-list .repeating-list {
  /* flex-flow: row wrap; */
}

.repeating-item {
  border: solid 1px #ddd;
  border-radius: 3px;

  background: white;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;

  display: flex;
  flex-direction: row;
}

.repeating-item-collapsed {
  background: #ddd;
}

.repeating-handle {
  padding: 5px;

  background-color: #eee;
  color: #999;
  display: flex;
  align-items: center;
}

.repeating-fields {
  flex: 1;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
}

.vertical-list .repeating-fields {
  flex-direction: column;
}

.horizontal-list .repeating-fields {
  flex-direction: row;
}

.repeating-tools {
  padding: 5px;
  color: #999;
}

.repeating-tools i {
  margin-left: 5px;
  cursor: pointer;
}

/*  */

.browser-pagination {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: row;
}

.browser-pagination button {
  margin: 10px;
}

/*  */

.link-browser label {
  font-weight: bold;
  margin-right: 5px;
}

.link-browser .form-inline .form-group {
  margin-right: 10px;
}

.link-toolbar,
.crop-toolbar {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #eee;
  padding: 5px;
}

.crop-times {
  margin-left: 10px;
  margin-right: 10px;
}

.content-item {
  margin: 10px;
  padding: 20px;
  position: relative;

  border: solid 1px #eee;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  width: 190px;
}

.content-item img {
  max-width: 100%;
  height: auto;
}

.content-browser {
  display: flex;
  flex-wrap: wrap;

  max-height: 70vh;
  overflow: auto;
}

.link-field .btn-link i {
  color: #666;
}

.color-field-background {
  background: url("https://d3970lb2lcqkxb.cloudfront.net/static/admin/content/no-pattern.png");
  background-size: 30px;
}

.color-field-preview {
  width: 50px;
  height: 20px;
  border-radius: 2px;
}

.media-field {
  width: 150px;
  height: 150px;
  background: url("https://d3970lb2lcqkxb.cloudfront.net/static/admin/content/no-pattern.png");
  background-size: 30px;

  display: flex;

  justify-content: center;
  position: relative;
  overflow: hidden;
}

.media-empty {
  color: #999;
  font-size: 3em;
  text-align: center;
  padding: 50px;
  display: flex;
  align-items: center;

  cursor: pointer;
}

.media-field img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.media-field-tools {
  color: #ffffff;
  position: absolute;
  bottom: 10px;
  display: flex;
  overflow: hidden;
  border-radius: 3px;
}

.media-field-tool-btn {
  color: #ffffff;
  background-color: #666;
  padding: 5px 10px;
  background: #3f4a56;
}

.media-field-tool-btn:hover {
  color: #fff;
  background-color: #6d7a89;
}

.media-browser {
  display: flex;
  flex-wrap: wrap;

  max-height: 70vh;
  overflow: auto;
}

.media-item {
  margin: 10px;
  padding: 20px;
  position: relative;

  border: solid 1px #eee;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  width: 190px;
}

.media-thumbnail {
  background: url("https://d3970lb2lcqkxb.cloudfront.net/static/admin/content/no-pattern.png");
  background-size: 30px;
  height: 150px;
  width: 150px;

  display: flex;
  /* align-items:center; */
  justify-content: center;
  flex-direction: column;
  text-align: center;

  &.sm {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.media-thumbnail i {
  font-size: 4rem;
}
.media-thumbnail img {
  object-fit: contain;
}

.media-item span,
.media-item h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.media-extension {
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 5px;
  background-color: #ccc;
  padding: 2px;
  font-size: 0.8rem;
}

.backdrop-cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.modal {
  z-index: 1400;
}
.modal-backdrop {
  z-index: 1300;
}

.upload-btn {
  margin-left: 5px;
}

.media-upload .alert {
  margin-top: 10px;
  margin-bottom: 10px;
}

.upload-thumbnail {
  max-width: 100px;
  max-height: 100px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.upload-thumbnail img {
  margin-right: 10px;
}

.module-popover {
  margin-top: 10px;
  position: relative;
  border: solid 1px #ccc;
  background: white;
  border-radius: 10px;
  padding: 10px;
}

.module-popover img {
  max-width: 75%;
  height: auto;
}

.related-version {
  display: flex;
  border: solid 1px #ddd;
  padding: 5px;
  margin-bottom: 10px;
  border-left-width: 5px;

  align-items: center;
  cursor: pointer;
}

.related-version p {
  font-size: 12px;
  color: #999;
}
.related-version h3 {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.related-version.active {
  background-color: #eee;
}

.related-version .icon {
  border-radius: 20px;

  width: 30px;
  height: 30px;

  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 10px;
}

.related-version.related-version-draft .icon {
  background-color: #f6b044;
}

.related-version.related-version-published .icon {
  background-color: #3ab97a;
}

.related-version.related-version-release .icon {
  background-color: #ac7ee9;
}

.related-version.related-version-archive .icon {
  background-color: #666666;
}

.related-version.active.related-version-draft {
  border-left-color: #f6b044;
}

.related-version.active.related-version-published {
  border-left-color: #3ab97a;
}

.related-version.active.related-version-release {
  border-left-color: #ac7ee9;
}

.aside-header select {
  margin-left: auto;

  width: initial;
  border: 0;
}

.settings-content-checkbox {
  display: flex;
  align-items: center;
}

.settings-content-checkbox input,
.settings-content-checkbox label {
  margin: 0;
  margin-left: 10px;
}

.language-select {
  color: #333;
}

.language-select:hover {
  color: #666;
  text-decoration: none;
}

.preview-rows {
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: solid 1px #ccc;
}

.flag {
  margin-right: 10px;
}

.flag-dropdown {
  svg {
    width: 20px;
    margin-right: 5px;
  }
}

.languages {
  display: flex;
  svg {
    width: 20px;
    margin-right: 5px;
  }
}
