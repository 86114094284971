// Here you can add other styles


.media-thumbnail {
  background-image: url("../assets/no-pattern.png");

  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 30px;

  border-radius: .5rem;
  overflow: hidden;
  flex-shrink: 0;
}


.media-upload-queue {
  position: fixed;
  z-index: 9;
  background: rgba(255,255,255,0.8);
  padding: 10px;
  bottom: 10px;


  .image-thumbnail {
    max-width: 60px;
  }

}




.variant-item {
  padding: 10px 0;
  border-bottom: solid 1px #ccc;
}

.variant-item label {
  margin-left: 10px;
  margin-right: 10px;
}


.image-thumbnail {
  max-width: 100px;
  max-height: 100px;
}



.support-content img {
  max-width: 100%;
}




.variant-row {
  cursor: pointer;
  img {
    width: 30px
  }
}




button.action-btn:disabled {
  cursor: not-allowed;

}




.bg-primary-light {
  color: #4f5d73;
  background-color: #b8daff;
}



.loading-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255, .8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}



.linePreloader{
  top: 0;
  left:0;
  right:0;
  // width: 100%;
  height:4px;
  background:linear-gradient(to right,#007bff,#007bff);
  background-color:#ccc;
  position: absolute;
  margin:auto;

  background-size:20%;
  background-repeat:repeat-y;
  background-position:-25% 0;
  animation:scroll 1.2s ease-in-out infinite;
}

@keyframes scroll{
  50%{background-size:80%}
  100%{background-position:125% 0;}
}
