
.form-control {
  color: #000000;
}


.form-group.is-invalid {
  .form-control{
    border-color: #dc3545;
  }
  .invalid-feedback {
    display: block;
  }
}

.form-check.is-invalid {

  .invalid-feedback {
    display: block;
  }
}

.block-attributes {
  label {
    font-size: .75rem;
    margin-bottom: .25rem;
  }
  .form-group {
    margin-bottom: .5rem;
  }
}

.nestable  {

  .nestable-item, .nestable-item-copy {


    border: solid 2px #eee;
    background-color: #eee;

    border-radius: .25rem;
    overflow: hidden;
    display: flex;


    .nestable-list {

      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 4px;
      margin-left: 4px;
      // display: flex;
      padding:0;
      padding-left: 0;
    }


    .nestable-content {
      flex:1;
      background-color: rgba(255, 255, 255, 1)
    }

    &.nestable-item--children-collapsed > .nestable-content {
      background-color: rgba(0, 0, 0, .25)
    }

    .nestable-item-name{
      font-size: .75rem;
      padding: .25rem;
      font-weight: bold;
    }

    .nestable-tools {
      padding: 3px;
    }

    .nestable-tools>.tools {
      svg {
        cursor: pointer;
        margin-right: 0.5rem;
      }
    }

    &.has-children {
      &>.nestable-tools>.tools{
        display: flex;
        flex-direction: column;

        svg {
          margin-bottom: .5rem;
          margin-right: 0rem;
        }
      }
    }

  }
}



.inline-items {
  padding: .5rem 0;
  min-height: 100px;

  &.inline-mode-select {
    min-height: 0px;
  }

  .empty-list {
    display: flex;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    border: dashed 2px #ddd;
    background: rgba(0,0,0,.05);
    margin: -.5rem 0;
  }

  .inline-item {
    border: solid 1px #ddd;
    border-radius: 3px;
    background: white;
    box-shadow: 0px 2px 5px rgba(0,0,0,.2);
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    position: relative;

    &.delete {
      background: #f5c6cb;
    }
  }
  .inline-content {
    padding: 1rem;
    display: flex;
    align-items: center;
  }
  .inline-tools {
    display: flex;
    align-items: center;
    margin-left: auto;

    .tool-btn {

      cursor: pointer;
      padding: 0rem 1rem;

    }

  }

  .drag-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    padding: 1rem .5rem;
    cursor: move;
  }


  &.compact {
    .badge {
      font-size: .5rem;
    }
    .drag-handle {

      padding: .5rem 0rem;
    }
    .tool-btn {

      cursor: pointer;
      padding: 0rem .3rem;

    }
    flex: 1;
    // padding: 0;
    .inline-content {

      margin-top:1rem;
      padding: .5rem;
      flex:1;
    }
    .inline-tools {
      position: absolute;
      left: 1rem;
    }
  }


}
.message-field {
  .form-group {
    margin-bottom: .5rem;
    label {
      margin-bottom: 0;
    }
  }
  .drag-handle {
    padding: .5rem 0rem;

  }
  .inline-tools {
    position: absolute;
    right: 0;

  }
  .inline-content {
    flex-direction: column;
    align-items: flex-start;
    padding: .5rem;
  }
}
.template-fields {
    // padding: 0 .5rem;
    // border: solid 1px #ddd;
    // border-radius: .5rem;
}



.group-field {
  .group-fields {

    padding: .5rem;
    border: solid 1px #ddd;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;

    .form-group {
      margin-right: 1rem;
    }
  }
}




.color-field-background {
  background-image: url("../assets/no-pattern.png");
  background-size: 30px;
}


.color-field-preview {

  width: 50px;
  height: 20px;
  border-radius: 2px;

}





/* Richtext */
.richtext-wrapper {
  border: solid 1px #ddd;

  .richtext-editor {
    padding: 0px 10px;
  }

  .rdw-editor-toolbar {
    background-color: #efefef;

    a {
      color: #000000;
    }
    .rdw-link-modal {
      height: initial;
    }
  }

}


.inline-field {

   .inline-content {
     padding: 0rem 1rem;

  }
  .form-group, .form-check {
    margin-right: .5rem;
  }

  // , input[type="text"]
  input[type="number"] {
    width: 7rem;
  }

  .related-field {
    min-width: 15rem;
  }
}



.form-group .form-checkbox {
  display: block;
}


.CodeMirror {
  resize: vertical;
  // overflow: auto !important;
  font-size: 1rem;

}


.fieldset {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
  background: white;
  margin-bottom: 2rem;


  display: flex;
  flex-direction: column;
  position: relative;

}


.detail-actions {
  position: sticky;
  bottom: 2rem;
}

.remove-media {
  cursor: pointer;
}

.media-field {


  &.media-thumbnail {
    cursor: pointer;
  }

}


.condition-group {
  flex: 1;

  .select, .related-select, .form-control {
    max-width: 200px;
    flex:1;
    margin-right: .5rem;
    color: #000000;
    border-color: #000000;
  }

  .related-select {
    max-width: initial;
  }


  .rule {


    .rule-tools {
      margin-left: auto;
      .btn {
        margin-left: .5rem;
      }
    }

    margin-top: .5rem;

    .divider {

      font-weight: bold;
      display: flex;

      margin-bottom: .5rem;
      font-weight: bold;
      display: flex;
      align-items: center;

      div {
        flex: 1;
        border-top: solid 1px #ccc;
        margin-left: 1rem;

      }
    }
  }

}
